import React from 'react';
import {
    ReferenceInput,
    ReferenceField,
    DateInput,
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    RadioButtonGroupInput,
    FormDataConsumer,
    SelectInput,
} from 'react-admin';

import { useFormStyles } from '../../../libs/sgx/components/layout';
import EditFormButtons from '../../../libs/sgx/components/EditFormButtons';

import { ESTADO_EJECUCION_VALUES } from '../../personas/utils';
import PersonaField from '../../personas/PersonaField';
import PersonaInput from '../../personas/PersonaAutocompleteInput';
import DivisaField from '../../cuentas/DivisaField';

import { cuentaSimple } from '../../cuentas-personales/utils';

const redirect = ({record}) => `/personas/${record.personaId}/show/pagos-ejecutados`;

const PagoEjecutadoEdit = props => {
    const classes = useFormStyles();
    return (
    <Edit {...props}>
        <SimpleForm toolbar={<EditFormButtons redirectOnSuccess={redirect} />}>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <DateInput source="fechaEsperada" validate={[required()]} />
            <DivisaField source="divisa" />
            <NumberInput source="monto" locales="es-AR" validate={[required()]} formClassName={classes.inlineBlock} />
            <NumberInput source="neto" locales="es-AR" formClassName={classes.inlineBlock} helperText="resources.pagos-ejecutados.fieldsHelper.neto" />
            <FormDataConsumer >
                {({ formData: {divisa, personaId = -1}, ...rest }) => (
                    <ReferenceInput source="cuentaPersonalId" reference="cuentas-personales" 
                        filter={{ divisa, personaId }}
                        {...rest}
                        >
                        <SelectInput optionText={cuentaSimple} resettable/>
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <ReferenceInput source="personaEncargadaId" reference="personas" allowEmpty>
                <PersonaInput />
            </ReferenceInput>
            <TextInput source="detalle" />
            <ReferenceInput source="personaProxyId" reference="personas" allowEmpty>
                <PersonaInput />
            </ReferenceInput>

            <DateInput source="fechaEjecucion" />
            <RadioButtonGroupInput source="estado" choices={ESTADO_EJECUCION_VALUES} />
        </SimpleForm>
    </Edit>
)
                };

export default PagoEjecutadoEdit;