import React from 'react';
import PropTypes from 'prop-types';
import {
    Labeled,
} from 'react-admin';
import DolarReferenceField from './DolarReferenceField';

const DolarReferenceFormField = ({ label, source, ...props }) => (
    <Labeled label={label}>
        <DolarReferenceField source={source} {...props} />
    </Labeled>
);

DolarReferenceFormField.propTypes = {
    record: PropTypes.object,
};

export default DolarReferenceFormField;
