import React from 'react';
import {
    Resource
} from 'react-admin';
import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import pagosEjecutados from './pagos-ejecutados';

const resourcesStaff = (permissions: SGXPermissions) => [
            <Resource name="pagos-ejecutados" {...pagosEjecutados(permissions)} />,
    ];

export default resourcesStaff;