import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SmallBarChart from '../charts/SmallBarChart';


const ProyectosCard = () => {

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Balance de Unidades de Negocio
        </Typography>
        <div style={{ height: '120px', width: '100%' }}>
          <SmallBarChart
            measures={[
              "ResumenProyectos.balance_dolares",
            ]}
            order={{
              "ResumenProyectos.balance_dolares": "desc"
            }}
            dimensions={[
              "ResumenProyectos.business_unit_name"
            ]}
            pivotConfigX="ResumenProyectos.business_unit_id"
            filters={[]}
            />
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" href="#/dashboard/business-units">Mas detalles</Button>
      </CardActions>
    </Card>
  );
}

export default ProyectosCard;
