import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { TODAY } from '../../../libs/sgx/shared/date';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { YEAR_RANGE } from '../index';
import PlantillaMensualChart from './PlantillaMensualChart';

const PlantillaCard = () => {
  const [selectedYear, setSelectedYear] = useState<string>(TODAY.year.toString());
  const handleDateRangeSelect = (year: string) => {
    setSelectedYear(year);
  };
  const timeDimensions = [
    {
      "dimension": "RetribucionesPorProyectos.fecha",
      "granularity": "month",
      "dateRange": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
    }
  ];
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Plantilla mensual
        </Typography>
        <PlantillaMensualChart timeDimensions={timeDimensions}/>
        <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
      </CardContent>
    </Card>
  );
}

export default PlantillaCard;
