import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Contrataciones from './charts/Contrataciones';
import PieChartField from '../../libs/sgx/components/PieChartField';

const ContratacionesCard = (props) => {
    const { personaId, year } = props;

    const personaIdFilters = personaId ? [
        {
            "member": "Contrataciones.persona_id",
            "operator": "equals",
            "values": [
                `${personaId}`
            ]
        }
    ] : [];

    const dateFilters = [
        {
            "member": "Contrataciones.fecha",
            "operator": "inDateRange",
            "values": [`${year}-01-01`,`${year}-12-31`],
        },
    ];

    const timeDimensions = [
        {
            "dimension": "Contrataciones.fecha",
            "granularity": "month",
        }
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
                <Typography gutterBottom variant="h6" display="block">
                    Utilización de horas
                </Typography>
                <div style={{ height: '40vh' }}>
                    <Contrataciones
                        measures={[
                            "Contrataciones.horas_contratadas",
                            "Contrataciones.horas_retribuidas",
                            "Contrataciones.horas_asignadas",
                        ]}
                        timeDimensions={timeDimensions}
                        filters={[...dateFilters, ...personaIdFilters]}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography gutterBottom variant="h6" display="block">
                    Horas sin asignación
                </Typography>
                <div style={{ height: '40vh' }}>
                    <PieChartField
                        measures={[
                            "Contrataciones.horas_sin_asignar"
                        ]}
                        dimensions={[
                            "Contrataciones.apellido"
                        ]}
                        filters={[...dateFilters, ...personaIdFilters]}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default ContratacionesCard;
