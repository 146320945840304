import React from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Pie } from 'react-chartjs-2';

import { cubejsApiTokenPromise, cubejsOptions } from '../../../layout/dashboard/charts/cube-config';

const cubejsApi = cubejs(cubejsApiTokenPromise, cubejsOptions);

const COLORS_SERIES = ['#95b8f6', '#add5fa', '#f9d99a', '#f9a59a', '#fa5f49'];
const commonOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
        }
    }
};


const renderChart = ({ resultSet, error, title, pivotConfig }) => {
    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
        return <LinearProgress />;
    }

    const data = {
        labels: resultSet.categories().map((c) => c.x),
        datasets: resultSet.series().map((s) => ({
            label: s.title,
            data: s.series.map((r) => r.value),
            backgroundColor: COLORS_SERIES,
            hoverBackgroundColor: COLORS_SERIES,
        })),
    };
    const options = { ...commonOptions };
    return (
        <div style={{ height: '40vh' }}>
            <Typography variant="inherit" color="textSecondary">
                {title}
            </Typography>
            <Pie data={data} options={options} />
        </div>
    );

};

const PieChartField = ({
    measures,
    dimensions,
    filters,
    pivotConfig = {},
    title,
    timeDimensions = [],
}) => {
    return (
        <QueryRenderer
            query={{
                measures,
                timeDimensions,
                "order": {},
                dimensions,
                filters
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) => renderChart({
                ...props,
                chartType: 'pie',
                pivotConfig,
                title,
            })}
        />
    );
};

export default PieChartField;
