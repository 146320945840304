import React from 'react';
import {
    List,
    Filter,
    SearchInput,
    SimpleList,
} from 'react-admin';

const JobListFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);


const JobList = props => (
    <List {...props} filters={<JobListFilter />}>
        <SimpleList linkType="show"
            primaryText={record => record.name} 
        />
    </List>
);

export default JobList;