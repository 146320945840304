import React from 'react';
import PropTypes from 'prop-types';
import PieChartField from '../../../libs/sgx/components/PieChartField';

const ProyectoAsignaciones = ({ proyectoId, dateRange }) => {
    return (
        <div style={{ height: '80vh' }}>
            <PieChartField
                title={dateRange}
                measures={[
                    "RetribucionesPorProyectos.monto_dolares",
                    "RetribucionesPorProyectos.horas_mensuales"
                ]}
                dimensions={[
                    "RetribucionesPorProyectos.job_nombre",
                ]}
                timeDimensions={[
                    {
                        "dimension": "RetribucionesPorProyectos.fecha",
                        dateRange
                    }
                ]}
                filters={[
                    {
                        "member": "RetribucionesPorProyectos.proyecto_id",
                        "operator": "equals",
                        "values": [
                            `${proyectoId}`
                        ]
                    }
                ]}
            />
        </div>
    );
}

ProyectoAsignaciones.propTypes = {
    proyectoId: PropTypes.number.isRequired,
    dateRange: PropTypes.string.isRequired,
};

export default ProyectoAsignaciones;