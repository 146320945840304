import React, { useState } from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TODAY } from '../shared/date';

interface DateFilterComponentProps {
  years: string[];
  onDateRangeSelect: (year: string) => void;
}

const DateFilterComponent: React.FC<DateFilterComponentProps> = ({ years, onDateRangeSelect }) => {
  const [selectedRange, setSelectedRange] = useState<string>(TODAY.year.toString());

  // Manejar el cambio de rango de fechas seleccionado
  const handleRangeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const year = e.target.value as string;
    setSelectedRange(year);
    // Notifica al componente padre el rango de fechas seleccionado
    onDateRangeSelect(year);
  };

  return (
    <div>
      <FormControl>
        <InputLabel id="date-range-select-label">Año</InputLabel>
        <Select
          labelId="date-range-select-label"
          id="date-range-select"
          value={selectedRange}
          onChange={handleRangeChange}
        >
          {years.map((year) => (
            <MenuItem value={year} key={year}>{year}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DateFilterComponent;
