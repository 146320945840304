import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

import ContratacionesCard from '../../layout/dashboard/ContratacionesCard';
import { YEAR_RANGE } from '../../layout/dashboard/index';
import DateFilterComponent from '../../libs/sgx/components/DateFilterComponent';
import { TODAY } from '../../libs/sgx/shared/date';

const PersonaMovimientos = (props) => {
    const [selectedYear, setSelectedYear] = useState(TODAY.year.toString());
    const { source } = props;
    const record = useRecordContext(props);
    const personaId = record[source];

    const handleDateRangeSelect = (year) => {
        setSelectedYear(year);
    };

    return <div>
        <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
        <ContratacionesCard personaId={personaId} year={selectedYear}/>
    </div>;
}

PersonaMovimientos.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default PersonaMovimientos;