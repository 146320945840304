// in src/Foo.js
import * as React from "react";

import SmallLineChart from '../charts/SmallLineChart';

const PlantillaMensualChart = ({timeDimensions}) => (
    <div style={{ height: '120px', width: '100%' }}>
          <SmallLineChart
            measures={[
              "RetribucionesPorProyectos.monto_dolares",
            ]}
            timeDimensions={timeDimensions}
            />
    </div>
);

export default PlantillaMensualChart;