import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';

import ReceiptIcon from '@material-ui/icons/Receipt';

import IngresosCreate from './IngresosCreate';
import IngresosEdit from './IngresosEdit';
import IngresosList from './IngresosList';
import IngresosShow from './IngresosShow';
import { PAYROLL_ROLES } from '../../roles-set';

const ingresos = (permisos: SGXPermissions) => ({
    icon: ReceiptIcon,
    create: IngresosCreate,
    edit: IngresosEdit,
    list: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? IngresosList : undefined,
    show: IngresosShow,
    options: {
        submenu: 'work'
    }
});

export default ingresos;