import React from 'react';
import {
    Resource
} from 'react-admin';
import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';
import pagoPivot from './pago-pivot';

const resourcesPivot = (permissions: SGXPermissions) => [
    <Resource name="pago-pivot" {...pagoPivot(permissions)} />,
];

export default resourcesPivot;