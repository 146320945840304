import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';
import { PAYROLL_ROLES } from '../../roles-set';

import PaymentIcon from '@material-ui/icons/LocalAtm';

import PagoEjecutadoCreate from './PagoEjecutadoCreate';
import PagoEjecutadoList from './PagoEjecutadoList';
import PagoEjecutadoShow from './PagoEjecutadoShow';
import PagoEjecutadoEdit from './PagoEjecutadoEdit';


const pagosEjecutados = (permisos: SGXPermissions) => ({
    icon: PaymentIcon,
    create: PagoEjecutadoCreate,
    edit: PagoEjecutadoEdit,
    list: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? PagoEjecutadoList : undefined,
    show: PagoEjecutadoShow,
    options: {
        submenu: 'staff'
    }
});

export default pagosEjecutados;
