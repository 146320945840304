import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    DateField,
} from 'react-admin';
import PersonaField from '../../personas/PersonaField';
import MontoField from '../../cuentas/MontoField';

const PagoEfectuadoShow = props => (
    <Show {...props} >
        <SimpleShowLayout>
            <ReferenceField source="personaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <DateField source="fechaEsperada" locales="es-AR" options={{ timeZone: 'UTC' }}/> 
            <MontoField source="monto" />
            <ReferenceField source="personaEncargadaId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
            <TextField source="estado" />
            <TextField source="detalle" />
            <DateField source="fechaEjecucion" locales="es-AR" options={{ timeZone: 'UTC' }}/> 
            <ReferenceField source="personaProxyId" reference="personas" link="show">
                <PersonaField />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);

export default PagoEfectuadoShow;