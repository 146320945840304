import React from 'react';
import Typography from '@material-ui/core/Typography';

import SmallBarChart from '../../layout/dashboard/charts/SmallBarChart';
import { useRecordContext } from 'react-admin';

const LegalEntityShowCustomerDebtCard = (props: any) => {
  const record = useRecordContext(props);
  return (
    <div>
      <Typography color="textSecondary" gutterBottom>
        Ingresos sin cobrar por cliente en dólares
      </Typography>
      <div style={{ height: '220px', width: '100%' }}>
        <SmallBarChart
          measures={[
            "IngresosFuturos.monto",
          ]}
          order={{
            "IngresosFuturos.monto": "desc"
          }}
          dimensions={[
            "IngresosFuturos.customer_nombre"
          ]}
          pivotConfigX="IngresosFuturos.customer_nombre"
          filters={[
            {
              "member": "IngresosFuturos.legal_entity_id",
              "operator": "equals",
              "values": [
                record.id.toString()
              ]
            }
          ]}
        />
      </div>
    </div>
  );
}

export default LegalEntityShowCustomerDebtCard;
