import React, { useState } from 'react';

import SmallLineChart from '../charts/SmallLineChart';
import DateFilterComponent from '../../../libs/sgx/components/DateFilterComponent';
import { TODAY } from '../../../libs/sgx/shared/date';
import { YEAR_RANGE } from '../index';

const PersonaMensualLineChart = ({personaId}: {
  personaId: number,
}) => {
  const [selectedYear, setSelectedYear] = useState<string>(TODAY.year.toString());
  const handleDateRangeSelect = (year: string) => {
    setSelectedYear(year);
  };
  const timeDimensions = [
    {
      "dimension": "RetribucionesPorProyectos.fecha",
      "granularity": "month",
      "dateRange": [`${selectedYear}-01-01`,`${selectedYear}-12-31`],
    }
  ];
  return (
    <div style={{ height: '120px', width: '100%' }}>
          <SmallLineChart
            measures={[
              "RetribucionesPorProyectos.valor_hora",
            ]}
            filters={[
              {
                "member": "RetribucionesPorProyectos.persona_id",
                "operator": "equals",
                "values": [
                  personaId.toString()
                ]
              }
            ]}
            timeDimensions={timeDimensions}
            />
            <DateFilterComponent years={YEAR_RANGE} onDateRangeSelect={handleDateRangeSelect} />
    </div>
)};

export default PersonaMensualLineChart;