import React from 'react';
import {
    Resource
} from 'react-admin';
import SGXPermissions from '../../libs/sgx/auth/SGXPermissions';

import proyectos from './proyectos';
import ingresos from './ingresos';

const resourcesWork = (permissions: SGXPermissions) => [
    <Resource name="proyectos" {...proyectos(permissions)} />,
    <Resource name="ingresos" {...ingresos(permissions)} />,
];

export default resourcesWork;