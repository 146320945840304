import * as React from "react";
import Grid from '@material-ui/core/Grid';
import {
    RowChartField,
    RowChartFieldData,
} from '../../../libs/sgx/components';

import {
    genericCurrency
} from '../../../libs/sgx/shared/currency';

import { TODAY } from '../../../libs/sgx/shared/date';
import { useRecordContext } from 'react-admin';
import PersonaMensualLineChart from '../../../layout/dashboard/plantilla/PersonaMensualLineChart';


const PersonaRetribucionesSummary = (props) => {
    const record = useRecordContext(props);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
                <RowChartField cube="MovimientosPorPersona"
                    filters={[
                        {
                            "member": "MovimientosPorPersona.persona_id",
                            "operator": "equals",
                            "values": [
                                `${record.id}`
                            ]
                        },
                        {
                            "member": "MovimientosPorPersona.anio",
                            "operator": "equals",
                            "values": [
                                `${TODAY.year}`
                            ]
                        },
                        {
                            "member": "MovimientosPorPersona.mes",
                            "operator": "equals",
                            "values": [
                                `${TODAY.month}`
                            ]
                        },
                    ]}
                    measures={[
                        "a_cobrar_pesos",
                        "a_cobrar_dolares",
                    ]}
                    dimensions={[
                        "anio",
                        "mes",
                    ]}
                    order={{
                        "MovimientosPorPersona.anio": "desc",
                        "MovimientosPorPersona.mes": "desc"
                    }}
                >
                    <RowChartFieldData icon="🇦🇷" source="a_cobrar_pesos" calculate={(row) => row && parseInt(row['MovimientosPorPersona.a_cobrar_pesos'] || 0, 10)} format={genericCurrency} />
                    <RowChartFieldData icon="🇺🇸" source="a_cobrar_dolares" />
                </RowChartField>
            </Grid>
            <Grid item xs={12} sm={9}>
                <PersonaMensualLineChart personaId={record.id}/>
            </Grid>
        </Grid>
    );
};

export default PersonaRetribucionesSummary;


