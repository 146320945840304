import React from 'react';
import {
    List,
    Filter,
    SearchInput,
    DateInput,
    SimpleList,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import { dayFormatter } from '../../../libs/sgx/shared/date';

const fechaFin = new Date().toISOString().substring(0, 10);

const ProyectoFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="fechaFin" initialValue={fechaFin}/>
        <ReferenceInput source="businessUnitId"
            alwaysOn
            reference="business-units"
            sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="legalEntityId"
            alwaysOn
            reference="legal-entities"
            sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="customerId"
            alwaysOn
            reference="customers"
            sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
    </Filter>
);

const ProyectoList = props => (
    <List {...props}
        filters={<ProyectoFilter />}
        bulkActionButtons={false}
        sort={{ field: 'fechaInicio', order: 'DESC' }}
    >
        <SimpleList
            primaryText={record => `${record.customerId ? '📝': '💼'}  ${record.nombre}`}
            secondaryText={record => dayFormatter(record.fechaInicio)}
            tertiaryText={record => record.fechaFin ? `Termina el ${dayFormatter(record.fechaFin)}` : '...'}
            linkType="show"
        />
    </List>
);

export default ProyectoList;