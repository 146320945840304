import * as React from 'react';
import { 
    useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';

import { useFormStyles } from '../../../libs/sgx/components/layout';
import MontoField from '../../cuentas/MontoField';

const PagoEjecutadoMontoField = ({ preferSource, ...props }) => {
    const classes = useFormStyles();
    const record = useRecordContext(props);

    if (record[preferSource]) {
        return <MontoField {...props} source={preferSource} className={classes.preferred} />;
    }
    return <MontoField {...props} className={classes.unpreferred} />;
}

PagoEjecutadoMontoField.defaultProps = {
   preferSource: undefined,
};

PagoEjecutadoMontoField.propTypes = {
    preferSource: PropTypes.string,
};

export default PagoEjecutadoMontoField;
