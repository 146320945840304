import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';
import {
    BUSINESS_ROLES,
    PAYROLL_ROLES,
} from '../../roles-set';
import BusinessIcon from '@material-ui/icons/Business';

import BusinessUnitCreate from './BusinessUnitCreate';
import BusinessUnitList from './BusinessUnitList';
import BusinessUnitShow from './BusinessUnitShow';
import BusinessUnitEdit from './BusinessUnitEdit';

const businessUnits = (permisos: SGXPermissions) => ({
    icon: BusinessIcon,
    create: permisos.hasAnyAssignment(...BUSINESS_ROLES) ? BusinessUnitCreate : undefined,
    edit: permisos.hasAnyAssignment(...BUSINESS_ROLES) ? BusinessUnitEdit : undefined,
    list: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? BusinessUnitList : undefined,
    show: BusinessUnitShow,
    options: {
        submenu: 'business'
    }
});

export default businessUnits;
