import React from 'react';
import { Title } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import SGXPermissions from '../libs/sgx/auth/SGXPermissions';
import { AsignacionesCard } from './dashboard/asignaciones';
import { IngresosCard } from './dashboard/ingresos';
import { ProyectosCard } from './dashboard/proyectos';
import { PlantillaCard } from './dashboard/plantilla';
import { HR_ROLES, PAYROLL_ROLES } from '../modules/roles-set';

const Dashboard = ({ permissions }: { permissions: SGXPermissions }) => {

  if (!permissions) {
    return (<span>🔐</span>);
  }

  return (<div>
    <Title title="ra.page.dashboard" />
    <Grid container spacing={1}>
      {
        permissions.hasAnyAssignment(...HR_ROLES) && <Grid item xs={12} sm={6}>
          <AsignacionesCard />
        </Grid>
      }
      {
        permissions.hasAnyAssignment(...PAYROLL_ROLES) && <Grid item xs={12} sm={6}>
          <IngresosCard />
        </Grid>
      }
      {
        permissions.hasAnyAssignment(...PAYROLL_ROLES) && <Grid item xs={12} sm={6}>
          <ProyectosCard />
        </Grid>
      }
      {
        permissions.hasAnyAssignment(...PAYROLL_ROLES) && <Grid item xs={12} md={6}>
          <PlantillaCard />
        </Grid>
      }
    </Grid>
  </div>
  )
};

export default Dashboard;