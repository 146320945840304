
import {
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
    HR,
} from './roles';

const BUSINESS_ROLES = [
    FINANCE,
    PARTNER,
];

const PAYROLL_ROLES = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
];

const HR_ROLES = [
    ADMINISTRATIVE,
    FINANCE,
    PARTNER,
    HR,
];


export {
    BUSINESS_ROLES,
    PAYROLL_ROLES,
    HR_ROLES,
};