
import ContratacionesCard from './ContratacionesCard';
import ResumenProyectosCard from './ResumenProyectosCard';
import RatesPorJob from './charts/RatesPorJob';

const YEAR_RANGE = ["2025", "2024","2023", "2022", "2021", "2020", "2019", "2018"];

export {
    ContratacionesCard,
    ResumenProyectosCard,
    RatesPorJob,
    YEAR_RANGE,
};
