import React from 'react';
import {
    DateInput,
    Edit,
    SimpleForm,
    useNotify,
    RadioButtonGroupInput,
    ReferenceField,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import PersonaField from '../../personas/PersonaField';

import { ESTADO_EJECUCION_VALUES } from '../../personas/utils';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const PagoEjecutadoRowEdit = props => {
    const notify = useNotify();
    const classes = useStyles();
    const onSuccess = () => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }});
    };
    return <Edit {...props} title=" " onSuccess={onSuccess} mutationMode="pessimistic">
        <SimpleForm>
            <ReferenceField source="personaEncargadaId" reference="personas" link="show" formClassName={classes.inlineBlock}>
                <PersonaField />
            </ReferenceField>
            <ReferenceField source="personaProxyId" reference="personas" link="show" formClassName={classes.inlineBlock}>
                <PersonaField />
            </ReferenceField>
            <DateInput source="fechaEjecucion"/>
            <RadioButtonGroupInput source="estado" choices={ESTADO_EJECUCION_VALUES} />
        </SimpleForm>
    </Edit>
};

export default PagoEjecutadoRowEdit;