import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import GastosProyecto from '../../../layout/dashboard/charts/GastosProyecto';

import ProyectoAsignaciones from './ProyectoAsignaciones';
import ProjectSummary from './components/ProjectSummary';

const ProyectoMovimientos = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const proyectoId = record[source];
  const dateRange = [
    record.fechaInicio || '2022-01-01',
    record.fechaFin || '2024-12-31',
  ];

  const esProyecto = !record.customerId;

  if (!esProyecto) {
    return (<span></span>);
  }
  return (
    <div>
      <div>
        <ProjectSummary proyectoId={proyectoId} />
      </div>
      <div style={{ height: '70vh' }}>
        <GastosProyecto proyectoId={proyectoId} dateRange={dateRange} moneda="dolares" />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <ProyectoAsignaciones proyectoId={proyectoId} dateRange="Last month"/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ProyectoAsignaciones proyectoId={proyectoId} dateRange="This month"/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ProyectoAsignaciones proyectoId={proyectoId} dateRange="Next month"/>
        </Grid>
      </Grid>
    </div>
  );
}

ProyectoMovimientos.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ProyectoMovimientos;