import SGXPermissions from '../../../libs/sgx/auth/SGXPermissions';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';

import { ProyectoCreate } from './ProyectoCreate';
import ProyectoEdit from './ProyectoEdit';
import ProyectoList from './ProyectoList';
import ProyectoShow from './ProyectoShow';
import {
    PAYROLL_ROLES,
    HR_ROLES,
} from '../../roles-set';

const proyectos = (permisos: SGXPermissions) => ({
    icon: FormatPaintIcon,
    create: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? ProyectoCreate : undefined,
    edit: permisos.hasAnyAssignment(...PAYROLL_ROLES) ? ProyectoEdit : undefined,
    list: permisos.hasAnyAssignment(...HR_ROLES) ? ProyectoList : undefined,
    show: ProyectoShow,
    options: {
        submenu: 'work'
    }
});

export default proyectos;